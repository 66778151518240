<template>
  <main>
    <form
      @submit.prevent="edit ? updateRole() : createRole(true)"
      autocomplete="on"
      class="flex flex-col gap-5 mt-6"
    >
      <div class="px-0">
        <!-- <label for="name" class="text-dark-800 text-sm font-medium"
          >Role Name</label
        > -->
        <easiTextInput
          placeholder="Role name"
          type="text"
          name="name"
          class="mt-1 mb-3"
          v-model="args.title"
          autocom
        ></easiTextInput>
      </div>

      <div class="px-0">
        <!-- <label for="description" class="text-dark-800 text-sm font-medium"
          >Role Description
        </label> -->
        <easiTextArea
          v-model="args.description"
          class="focus:border-gray border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-lg mt-1 mb-6"
          name="description"
          id="description"
          placeholder="Role Description"
          cols="30"
          rows="5"
        ></easiTextArea>
      </div>

      <div class="px-0">
        <label class="text-dark-800 text-sm font-medium"
          >Select Team Member (s)</label
        >
        <easiEmployeeBonus
          @update="args.employeeIds = $event"
          :value="args.employeeIds"
          :options="createEmpList()"
          :clearAll="clear"
          class="mb-3"
          :placeHolder="'Select Team Member(s)'"
        />
      </div>

      <p class="text-dark-800 md:text-base text-sm text-center">
        Please select any of the following permission you <br />
        would like to assign to this role
      </p>
      <hr class="border md:hidden border-dividerColor my-2" />

      <div
        class="md:grid bg-newLimeGreen p-2 flex flex-col md:grid-cols-2 md:justify-start gap-x-12"
      >
        <div
          v-for="head in headers"
          :key="head"
          class="flex flex-col gap-1 mb-4 items-start h-auto flex-1 self-start"
        >
          <div class="flex gap-x-4 items-center">
            <input
              @change="selectPrivilege(head)"
              :checked="selectAllPrivilege.includes(head)"
              type="checkbox"
              class="border-2 border-dark-600"
              name=""
              id=""
            />

            <span class="uppercase font-bold w-full text-dark-800">{{
              formatPrivilege(head)
            }}</span>
          </div>

          <div
            class="flex gap-x-4 items-center justify-between my-1"
            v-for="el in privList[head]"
            :key="el.id"
          >
            <input
              :checked="args.privileges.includes(el._id)"
              @change="selectPrivilegeItem(el._id, head)"
              type="checkbox"
              name=""
              class="flex-1"
              id=""
            />

            <span class="capitalize text-dark-800">{{
              formatPrivilege(el.title)
            }}</span>
          </div>
        </div>
      </div>

      <hr class="border-0.5 border-dividerColor my-2" />

      <div
        v-if="!edit"
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <div class="w-full">
          <easiButton
            type="button"
            @click="save"
            :loading="saveLoad"
            variant="outlined"
            color="primary"
            class="w-full"
          >
            <span class="text-sm"> Save And Add More </span>
          </easiButton>
        </div>
        <div class="w-full">
          <easiButton
            :loading="loading"
            type="submit"
            color="primary"
            class="w-full"
          >
            <span class="text-sm"> Submit </span>
          </easiButton>
        </div>
      </div>
      <div
        v-if="edit"
        class="w-full mt-7 mb-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="cancelOperation"
          class="w-80 md:w-40 cursor-pointer text-center bg-white rounded-full text-dark-800 border border-secondary px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          :loading="loading"
          class="w-80 md:w-40 focus:outline-none text-sm py-3 px-4 bg-secondary rounded-full text-white"
        >
          Update
        </easiButton>
      </div>
    </form>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Role {{ edit ? "updated" : "added" }} successfully</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import UploadComponent from "@/components/global/UploadComponent";
import BankDetail from "@/components/Employee/Edit/Benefits/BankDetails.vue";
import ManageRole from "@/components/Settings/Business/ManageRoles.vue";
import NigerianStates from "@/utils/states";
import IndustryList from "@/utils/Industry";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();

const { getAllEmployees } = storeToRefs(store);
const props = defineProps({
  edit: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Array,
    default: [],
  },
  roleData: {
    type: Array,
    default: [],
  },
});
const emit = defineEmits(["completed"]);

const { processDate, validatePhone, validateEmail, resetObj } = helperFunctions;
const visibleRef = ref(false);
const imgsRef = ref("");
const allEmployees = computed(() => getAllEmployees.value.data);
const profileArgsFromStore = computed(() => store.profileData);
const clear = ref(false);

const deleteWarning = ref(false);
const addBank = ref(false);
const show = ref(false);
const loading = ref(false);
const saveLoad = ref(false);
const updateSuccess = ref(false);
const manageAccess = ref(false);
const changeRole = ref(false);
const addMember = ref(false);
const activeRole = ref(true);
// const companyData = computed(() => store.getCompanyDetails);
const argsArray = ref([]);
const selectAllPrivilege = ref([]);
const selectedPrivilege = ref([]);
const headers = ref([]);
const privList = ref([]);

const errorRules = ref({
  phone: "",
  workEmail: "",
  otherPhoneNumbers: "",
  businessName: "",
});
const imgLink = ref("@/assets/img/cert.png");
const industryData = IndustryList.map((el) => el.name);

const args = reactive({
  title: "",
  privileges: [],
  description: "",
  employeeIds: [],
});
const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  return stateArray;
});
const updateProfile = () => {
  console.log(args);
};

function cancelOperation() {
  emit("completed");
}

const onPreview = (img) => {
  console.log(img);
  imgsRef.value = img;
  visibleRef.value = true;
};

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};
const onHide = () => (visibleRef.value = false);
function addMore(arg) {
  const employees = args.employeeIds.map((emp) => emp);
  args.employeeIds = employees;

  const privileges = args.privileges.map((priv) => priv);
  args.privileges = privileges;
  const newObj = Object.assign({}, args);

  argsArray.value.push(newObj);

  store.$patch((state) => {
    state.profileArr.push(newObj);
  });
}

async function save() {
  addMore(args);

  await createRole(false);
  args.privileges = args.privileges.splice(0);
  args.employeeIds = args.employeeIds.splice(0);
  selectAllPrivilege.value = selectAllPrivilege.value.splice(0);

  args.employeeIds = [];
  args.privileges = [];
  selectAllPrivilege.value = [];
  clear.value = true;

  Object.assign(args, resetObj(args, "args"));
}

const reloadPage = () => {
  store.$patch({
    pageIndex: 0,
  });
  window.location.reload();
};
const selectPrivilege = (item) => {
  if (selectAllPrivilege.value.includes(item)) {
    selectedPrivilege.value = selectedPrivilege.value.filter(
      (val) => !privList.value[item].includes(val)
    );
    args.privileges = selectedPrivilege.value.map((priv) => priv._id);
    let index = selectAllPrivilege.value.indexOf(item);
    if (index > -1) {
      selectAllPrivilege.value.splice(index, 1);
    }
  } else {
    selectAllPrivilege.value.push(item);

    selectedPrivilege.value.push(...privList.value[item]);
    args.privileges = selectedPrivilege.value.map((priv) => priv._id);
  }
};

const selectPrivilegeItem = (id, head) => {
  if (selectAllPrivilege.value.includes(head)) {
    let index = selectAllPrivilege.value.indexOf(head);
    if (index > -1) {
      selectAllPrivilege.value.splice(index, 1);
    }
  }

  if (args.privileges.includes(id)) {
    let index = args.privileges.indexOf(id);
    if (index > -1) {
      args.privileges.splice(index, 1);
    }
  } else {
    args.privileges.push(id);
  }

  console.log(args.privileges.length);
};
async function queryRole() {
  try {
    await query({
      endpoint: "ListRoles",
      service: "SETTINGS",
      storeKey: "roles",
    });
  } catch (e) {
    console.log(e);
  }
}
const createRole = async (final) => {
  if (final) {
    addMore(args);
    loading.value = true;
  } else {
    saveLoad.value = true;
  }

  try {
    let res = await mutate({
      endpoint: "CreateRoleV3",
      data: { input: profileArgsFromStore.value },
      service: "SETTINGS",
    });

    if (res.success) {
      await queryRole();
      if (final) {
        updateSuccess.value = true;
        loading.value = false;
        setTimeout(() => {
          updateSuccess.value = false;
          emit("completed");
        }, 1000);
      } else {
        toast.success("Details saved!");
      }
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  } finally {
    loading.value = false;
    saveLoad.value = false;
  }
};

const updateRole = async () => {
  loading.value = true;
  const roleId = props.roleData[0]._id;

  try {
    let res = await mutate({
      endpoint: "UpdateRole",
      data: { input: args, roleId },
      service: "SETTINGS",
    });

    if (res.success) {
      updateSuccess.value = true;
      loading.value = false;
      setTimeout(() => {
        updateSuccess.value = false;
        emit("completed");
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
};

const addEmployeeToRole = async (roleId) => {
  try {
    let res = await mutate({
      endpoint: "AddEmployeesToRole",
      data: { input: { employeeIds: employeeIds.value }, roleId },
      service: "SETTINGS",
    });

    if (res.success) {
      loading.value = false;

      updateSuccess.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
};
function assignValues() {
  console.log(props.roleData, "RDATA");
  if (props.roleData && props.roleData.length) {
    args.description = props.roleData[0].description;
    args.title = props.roleData[0].title;
    args.privileges = props.roleData[0].privileges.map((priv) => priv._id);
    args.employeeIds = props.roleData[0].employees.map((emp) => emp._id);
  }
}
assignValues();

onMounted(() => {
  // assignValues();
  const result = props.data.reduce(function (r, a) {
    r[a.groupName] = r[a.groupName] || [];
    r[a.groupName].push(a);
    return r;
  }, {});
  console.log(result, "ROLE");
  headers.value = Object.keys(result);
  privList.value = result;

  store.$patch({
    profileArr: [],
  });
});

function formatPrivilege(text) {
  if (text !== "ALL") {
    return text.replace(/_/g, " ");
  }
  return text;
}
</script>

<style></style>
