<template>
  <main
    :class="
      manageAccess
        ? 'w-full md:w-11/12 xl:w-11/12  pb-10'
        : 'md:w-3/5 xl:w-3/5  rounded-2xl shadow-lg'
    "
    class="w-full mx-auto bg-white"
  >
    <div v-if="!manageAccess" class="w-full px-6 py-8">
      <div class="w-full flex items-center justify-between">
        <h4 class="text-2xl md:text-3xl font-medium">Team</h4>
        <div class="flex items-center gap-2">
          <!-- <img class="w-3 md:hidden" src="@/assets/icons/switch.svg" alt="" /> -->
          <span
            @click="manageAccess = true"
            class="md:w-auto bg-primary flex items-center justify-center gap-3 font-medium cursor-pointer text-center rounded-full text-dark-800 md:border md:border-btnPri px-3 text-sm py-2"
          >
            <img
              class="hidden md:block w-5"
              src="@/assets/icons/switch-nobg.svg"
              alt=""
            />

            <span class="flex items-center text-sm text-white">
              Manage Roles
            </span></span
          >
          <span
            @click="addMember = true"
            class="md:w-auto flex items-center justify-center gap-3 font-medium cursor-pointer text-center bg-white rounded-full text-primary md:border md:border-primary px-3 text-sm py-2"
          >
            <img class="w-3" src="@/assets/icons/newPlus1.svg" alt="" />

            <span class="md:flex hidden items-center text-sm text-primary">
              Add Team Member
            </span></span
          >
        </div>
      </div>

      <hr class="text-grey my-4" />

      <div
        v-if="
          allRoles &&
          allRoles.length &&
          employeesFromRoles &&
          employeesFromRoles.length
        "
      >
        <div v-for="header in headers" :key="header">
          <div v-for="role in visibleData[header]" :key="role._id">
            <div
              v-for="emp in role.employees"
              :key="emp._id"
              class="shadow-md border my-4 bg-white border-outlineGray hover:border-dark-800 rounded-xl relative w-full py-2 px-4 flex flex-col md:flex-row justify-center items-center gap-4"
            >
              <div class="w-full flex flex-wrap items-start gap-2">
                <div class="flex items-center gap-x-3">
                  <div
                    class="rounded-full w-16 h-16 md:w-24 md:h-24 border-4 border-white overflow-hidden"
                    :class="
                      !emp.profile.pfp ? 'md:p-6 pt-3 md:pt-7 text-center' : ''
                    "
                    :style="
                      !emp.profile.pfp
                        ? `background-color:${getAvatar(emp).color}`
                        : ''
                    "
                  >
                    <img
                      v-if="emp.profile.pfp"
                      :src="emp.profile.pfp"
                      class="object-cover object-center w-full"
                      alt=""
                    />

                    <span
                      class="text-white font-medium text-xl text-center"
                      v-else
                      >{{ getAvatar(emp).name }}</span
                    >
                  </div>
                  <div class="flex flex-col justify-center">
                    <h4
                      class="font-bold md:font-medium text-dark-900 text-sm md:text-lg capitalize"
                    >
                      {{ emp.profile.firstName }} {{ emp.profile.lastName }}
                    </h4>
                    <h4
                      class="font-bold text-sm md:text-sm text-dark-600 capitalize"
                    >
                      {{ role.title }}
                    </h4>
                  </div>
                </div>
                <div
                  @click="setActive(role._id, emp._id)"
                  class="absolute right-4 cursor-pointer mt-2 z-50 w-4"
                >
                  <img src="@/assets/icons/more-icon.svg" alt="" />
                </div>
                <div
                  v-show="
                    show &&
                    active.includes(role._id) &&
                    active.includes(emp._id)
                  "
                  class="w-40 group-hover:text-dark-800 flex absolute right-8 mt-4 flex-col z-50 bg-white shadow-lg"
                >
                  <div
                    class="flex flex-col z-50 text-left items-start text-dark-800 text-sm cursor-pointer pt-2"
                  >
                    <span
                      class="hover:bg-mint text-dark-800 font-medium py-2 px-4 w-full"
                      @click="fetchIds(role._id, emp._id, 'change')"
                    >
                      Change Role
                    </span>

                    <span
                      @click="fetchIds(role._id, emp._id, 'remove')"
                      class="text-dark-800 font-medium hover:bg-mint py-2 px-4 w-full"
                      >Remove</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <easiEmptyState v-else text="No Team member added" :hideButton="true" />

      <hr
        v-show="
          allRoles &&
          allRoles.length &&
          employeesFromRoles &&
          employeesFromRoles.length
        "
        class="my-6 border-0.5 border-dividerColor"
      />
    </div>

    <div v-else class="w-full md:px-6 px-2 py-10 relative">
      <div class="flex items-center justify-between pb-8">
        <h1 class="font-bold text-xl">Manage Roles</h1>
        <span @click="manageAccess = false" class="cursor-pointer">
          <img src="@/assets/icons/close.svg" alt="" />
        </span>
      </div>

      <ManageRole class="w-full" />
    </div>

    <easiImageView
      :visible="visibleRef"
      :imgs="imgsRef"
      @hide="onHide"
    ></easiImageView>

    <div
      @click="show = false"
      :class="!show ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <easiModal v-if="changeRole" @close="closeChangeModal" :isBorder="false">
      <template v-slot:header
        ><h2 class="font-medium text-dark-800">Change Role</h2></template
      >
      <hr class="border-0.5 border-dividerColor mt-1 w-9/12 mx-auto" />
      <p class="font-bold text-dark-800 text-center my-5">Please Select Role</p>
      <div
        class="flex flex-col gap-4 mx-auto w-9/12 mx-auto h-40 overflow-y-scroll"
      >
        <div
          v-for="role in allRoles"
          :key="role._id"
          class="flex items-center cursor-pointer gap-x-5 p-3 border border-lightSec shadow-lg bg-newLimeGreen rounded-lg"
          @click="newRoleId = role._id"
        >
          <img
            v-show="newRoleId === role._id"
            class="self-end justify-self-start place-self-start w-5"
            src="../../../assets/icons/checked.svg"
            alt=""
          />
          <div
            v-show="newRoleId !== role._id"
            class="w-5 h-5 rounded-full border-2 border-dark-400 bg-white self-end justify-self-start place-self-start"
          ></div>

          <p class="text-dark-800 text-sm font-medium capitalize">
            {{ role.title }}
          </p>
        </div>
      </div>

      <div class="grid mx-auto w-9/12 my-5">
        <easiButton @click="removeEmployeeFromRole" class="w-full"
          >Submit</easiButton
        >
      </div>
    </easiModal>

    <easiModal v-if="addMember" @close="addMember = false">
      <template v-slot:header>
        <h4 class="text-2xl md:text-3xl font-medium">
          Add Team Member
        </h4></template
      >
      <form
        @submit.prevent="addEmployeeToRole('add', true)"
        autocomplete="on"
        class="flex flex-col gap-5 mt-6"
      >
        <div class="px-6 mt-3">
          <label class="text-dark-800 text-sm font-medium"
            >Select Team Member
          </label>
          <easiEmployeeBonus
            @update="empToRoleArgs.employeeIds = $event"
            :value="empToRoleArgs.employeeIds"
            :options="createEmpList()"
            class="mb-3"
            :placeHolder="'Select Team Member'"
          />
        </div>

        <div class="px-6">
          <label for="role" class="text-dark-800 text-sm font-medium"
            >Select Role</label
          >
          <easiSelectInput2
            class="mt-1 mb-3"
            @update="empToRoleArgs.roleId = $event"
            :value="empToRoleArgs.roleId"
            :options="roleOptions"
          />
        </div>

        <hr class="border-0.5 border-dividerColor my-2" />

        <div
          class="w-full px-8 my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
        >
          <div class="w-full">
            <easiButton
              type="button"
              @click="save"
              :loading="saveLoad"
              variant="outlined"
              color="primary"
              class="w-full"
            >
              <span class="text-sm"> Save And Add More </span>
            </easiButton>
          </div>
          <div class="w-full">
            <easiButton
              :loading="loading"
              type="submit"
              color="primary"
              class="w-full"
            >
              <span class="text-sm"> Submit </span>
            </easiButton>
          </div>
        </div>
      </form>
    </easiModal>

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span
          >Are you sure you want to remove this employee from the team?</span
        >
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              @click="removeEmployeeFromRole"
              class="w-40 min-w-full"
              :loading="loading"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ successMessage }}</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import UploadComponent from "@/components/global/UploadComponent";
import BankDetail from "@/components/Employee/Edit/Benefits/BankDetails.vue";
import ManageRole from "@/components/Settings/Business/ManageRoles.vue";
import NigerianStates from "@/utils/states";
import IndustryList from "@/utils/Industry";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();

const { getAllEmployees } = storeToRefs(store);

const { processDate, validatePhone, validateEmail, resetObj } = helperFunctions;
const visibleRef = ref(false);
const imgsRef = ref("");
const allEmployees = computed(() =>
  getAllEmployees.value ? getAllEmployees.value.data : []
);
const allRoles = computed(() => store.getRoles.data);
const allPrivileges = computed(() => store.getPrivileges.data);
const profileArgsFromStore = computed(() => store.profileData);
const roleOptions = computed(() =>
  allRoles.value.map((role) => {
    return {
      label: role.title.toUpperCase(),
      value: role._id,
    };
  })
);

const updateSuccess = ref(false);
const deleteWarning = ref(false);
const addBank = ref(false);
const show = ref(false);
const loading = ref(false);
const manageAccess = ref(false);
const changeRole = ref(false);
const addMember = ref(false);
const activeRole = ref(true);
// const companyData = computed(() => store.getCompanyDetails);
const argsArray = ref([]);
const visibleData = ref([]);
const headers = ref([]);
const active = ref([]);
const successMessage = ref("");
const employeesFromRoles = ref([]);
const newRoleId = ref("");
const args = ref({
  employeeId: "",
  roleId: "",
});

const empToRoleArgs = reactive({
  employeeIds: [],
  roleId: "",
});

function closeChangeModal() {
  changeRole.value = false;
  empToRoleArgs.employeeIds = [];
  empToRoleArgs.roleId = "";
}

const errorRules = ref({
  phone: "",
  workEmail: "",
  otherPhoneNumbers: "",
  businessName: "",
});
const imgLink = ref("@/assets/img/cert.png");
const industryData = IndustryList.map((el) => el.name);
function setActive(roleId, empId) {
  show.value = !show.value;
  active.value = [];
  active.value.push(roleId, empId);
}

function getBgColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const getAvatar = (employee) => {
  let name = "",
    color;
  let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
  // const empId = employee.job && employee.job.userName;
  // const thenum = empId ? Number(empId.replace(/\D/g, "")) : 1;
  const initialOne = String(
    employee &&
      employee.profile &&
      employee.profile.firstName &&
      employee.profile.firstName.charAt(0)
  );
  const initialTwo = String(
    employee &&
      employee.profile &&
      employee.profile.lastName &&
      employee.profile.lastName.charAt(0)
  );
  const num =
    Number(alphabet.indexOf(initialOne.toLowerCase())) +
    Number(alphabet.indexOf(initialTwo.toLowerCase()));
  color = getBgColor(num);
  // Get color

  employee
    ? (name = `${
        employee &&
        employee.profile &&
        employee.profile.firstName &&
        employee.profile.firstName.charAt(0)
      }${
        employee &&
        employee.profile &&
        employee.profile.lastName &&
        employee.profile.lastName.charAt(0)
      }`)
    : "";

  return {
    color,
    name,
  };
};

function fetchIds(role, emp, action) {
  if (action === "remove") {
    deleteWarning.value = true;
  } else {
    changeRole.value = true;
    newRoleId.value = role;

    empToRoleArgs.employeeIds.push(emp);
    empToRoleArgs.roleId = role;
  }
  args.value.roleId = role;
  args.value.employeeId = emp;
}

const getColor = (index) => {
  let borderColor;
  let bgColor;
  // Get color
  let num;

  const bg = ["#DFE5FF", "#E8FFE4", "#FFEBB8", "#A2F5E6", "#FFDCD2", "#FBE4FF"];
  const border = [
    "#023C98",
    "#19A301",
    "#E0A409",
    "#01AA8C",
    "#E4572E",
    "#5B0169",
  ];
  if (index > 9) {
    let firstDigit = String(index).charAt(0);
    num = Number(firstDigit);
  } else {
    num = index;
  }

  // const randomNumber = Math.floor(Math.random() * 5);
  borderColor = border[num];
  bgColor = bg[num];

  return {
    borderColor,
    bgColor,
  };
};

const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  return stateArray;
});
const updateProfile = () => {
  console.log(args);
};

const onPreview = (img) => {
  console.log(img);
  imgsRef.value = img;
  visibleRef.value = true;
};
async function queryRole() {
  loading.value = true;

  try {
    await query({
      endpoint: "ListRoles",
      service: "SETTINGS",
      storeKey: "roles",
    });

    if (allRoles.value) {
      const emps = allRoles.value.map((emp) => {
        return emp.employees;
      });
      employeesFromRoles.value = emps.flat();
      console.log(emps.flat());
      const result = allRoles.value.reduce(function (r, a) {
        r[a.title] = r[a.title] || [];
        r[a.title].push(a);
        return r;
      }, {});
      headers.value = Object.keys(result);
      visibleData.value = result;
    }

    loading.value = false;
  } catch (e) {
    loading.value = false;
  }
}
async function queryPrivileges() {
  try {
    await query({
      endpoint: "ListPrivileges",
      service: "SETTINGS",
      storeKey: "privileges",
    });
  } catch (e) {}
}
queryPrivileges();
queryRole();
const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};
const onHide = () => (visibleRef.value = false);
function addMore() {
  const args = {
    employeeIds: [],
    roleId: "",
  };
  args.employeeIds = empToRoleArgs.employeeIds.map((id) => id);
  args.roleId = empToRoleArgs.roleId;
  const newObj = Object.assign({}, args);

  argsArray.value.push(newObj);

  store.$patch((state) => {
    state.profileArr.push(newObj);
  });
}

async function save() {
  addMore();
  await addEmployeeToRole("add", false);
  // Object.assign(empToRoleArgs, resetObj(empToRoleArgs, "empToRoleArgs"));
  empToRoleArgs.employeeIds = empToRoleArgs.employeeIds.splice(0);
  empToRoleArgs.roleId = "";
  // console.log(empToRoleArgs);
}
const saveLoad = ref(false);
const addEmployeeToRole = async (action = "add", final) => {
  if (action === "change") {
    empToRoleArgs.roleId = newRoleId.value;
  }
  if (final) {
    loading.value = true;
    addMore();
  } else {
    saveLoad.value = true;
  }
  try {
    let res = await mutate({
      endpoint: "AddEmployeesToRole",
      data: { input: profileArgsFromStore.value },
      service: "SETTINGS",
    });

    if (res.success) {
      loading.value = false;
      await queryRole();
      if (final) {
        if (action === "change") {
          successMessage.value = "Team member's role changed successfully";
          changeRole.value = false;
        } else {
          successMessage.value = "Team member(s) added successfully";
        }
      } else {
        toast.success("Details saved!");
      }

      if (allRoles.value) {
        const result = allRoles.value.reduce(function (r, a) {
          r[a.title] = r[a.title] || [];
          r[a.title].push(a);
          return r;
        }, {});
        headers.value = Object.keys(result);
        visibleData.value = result;
      }
      if (final) {
        updateSuccess.value = true;
        addMember.value = false;

        setTimeout(() => {
          updateSuccess.value = false;
        }, 1000);
      }
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  } finally {
    loading.value = false;
    saveLoad.value = false;
  }
};
async function removeEmployeeFromRole() {
  loading.value = true;
  try {
    let res = await mutate({
      endpoint: "RemoveEmployeeFromRoleV3",
      data: { input: args.value },
      service: "SETTINGS",
    });

    if (res.success) {
      if (!empToRoleArgs.employeeIds.length) {
        loading.value = false;
        deleteWarning.value = false;
        successMessage.value = "Team member removed successfully";

        await queryRole();
        if (allRoles.value) {
          const result = allRoles.value.reduce(function (r, a) {
            r[a.title] = r[a.title] || [];
            r[a.title].push(a);
            return r;
          }, {});
          headers.value = Object.keys(result);
          visibleData.value = result;
        }
        updateSuccess.value = true;
        deleteWarning.value = false;
        setTimeout(() => {
          updateSuccess.value = false;
        }, 1000);
      } else {
        await addEmployeeToRole("change", true);
      }
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}

onMounted(() => {
  if (allRoles.value) {
    const result = allRoles.value.reduce(function (r, a) {
      r[a.title] = r[a.title] || [];
      r[a.title].push(a);
      return r;
    }, {});
    headers.value = Object.keys(result);
    visibleData.value = result;
  }

  store.$patch({
    profileArr: [],
  });
  // console.log(result);
});
const reloadPage = () => {
  store.$patch({
    pageIndex: 0,
  });
  window.location.reload();
};
</script>

<style>
.color {
  color: #b9bbc07d;
  color: #e0e0e08b;
}
</style>
