<template>
  <main
    class="flex bg-white min-w-full md:bg-transparent pt-4 border-t border-dark-900 p-0 flex-col md:flex-row items-start"
  >
    <div
      class="flex mx-0 md:mx-0 flex-row items-start md:pb-0 pb-4 mb-8 md:mb-0 gap-x-4 md:flex-col w-full md:pr-4 md:w-52 md:items-start h-full"
    >
      <div class="w-full">
        <div
          class="md:p-2 py-1 text-center md:text-left bg-primary w-full mb-4 text-white text-xs"
        >
          DEFAULT ROLES
        </div>

        <button
          v-for="role in allRoles"
          :key="role._id"
          v-show="defaultRoles.includes(role.title.toLowerCase())"
          @click="switchView(role._id)"
          :class="
            role._id === activeId
              ? 'bg-newLimeGreen  text-primary font-bold  '
              : 'text-dark-800'
          "
          class="capitalize text-base px-4 py-3 focus:outline-none text-center md:text-left whitespace-nowrap w-fit md:border-0 md:w-full"
        >
          {{ role.title }}
        </button>
      </div>
      <div>
        <div class="p-2 w-full md:mt-8 text-dark-800 font-semibold text-base">
          Custom Roles
        </div>
        <div class="p-2 w-full mb-4 text-darkGray font-normal text-sm">
          You can create your own custom roles, and choose exactly what team
          members can see and do.
        </div>
      </div>

      <span
        @click="addRole = true"
        class="md:w-auto bg-primary flex items-center justify-center gap-x-2 font-medium cursor-pointer text-center rounded-full text-white px-3 mb-4 text-sm py-2"
      >
        <img class="w-3" src="@/assets/icons/newPlus.svg" alt="" />

        <span class="md:flex hidden items-center text-xs text-white">
          Create New Role
        </span></span
      >

      <div class="w-full md:h-auto h-40 overflow-y-scroll">
        <button
          v-for="role in allRoles"
          :key="role._id"
          v-show="!defaultRoles.includes(role.title.toLowerCase())"
          @click="switchView(role._id)"
          :class="
            role._id === activeId
              ? 'bg-lightSecondary  text-secondary font-semibold  '
              : 'text-dark-800'
          "
          class="capitalize text-sm px-4 py-3 focus:outline-none text-center md:text-left whitespace-nowrap w-fit md:border-0 md:w-full"
        >
          {{ role.title }}
        </button>
      </div>
    </div>

    <div class="w-full" v-if="!isEdit">
      <div
        v-for="data in visibleData"
        :key="data._id"
        class="flex flex-col gap-4 max-w-full text-sm h-auto bg-white md:border-l md:pl-6"
      >
        <div class="flex justify-between w-full items-center">
          <span
            class="text-xl capitalize md:text-2xl font-medium text-secondary"
            >{{ data.title }}</span
          >
          <!-- Hide Button temporarily-->
          <easiButton
            v-show="!defaultRoles.includes(data.title.toLowerCase())"
            @click="isEdit = true"
            size="small"
            color="primary"
          >
            <span class="flex items-center text-sm gap-3">
              Edit
              <img class="w-3" src="@/assets/icons/edit.svg" alt="" />
            </span>
          </easiButton>
        </div>
        <span class="text-sm text-dark-800">{{
          data.description || "N/A"
        }}</span>
        <span class="text-lg text-dark-900 font-medium"
          >Team Members with the role</span
        >
        <div
          v-if="data.employees && data.employees.length"
          class="flex flex-wrap min-w-full gap-2 justify-start items-center"
        >
          <div
            v-for="emp in data.employees"
            :key="emp._id"
            class="flex items-center justify-around gap-x-3 rounded-lg px-2 py-0.5 text-center bg-newLimeGreen border border-primary text-xs w-auto"
          >
            <span
              class="w-8 h-8 inline-flex flex-none items-center justify-center rounded-full"
              :style="`background-color:${
                initials(`${emp.profile.firstName} ${emp.profile.lastName}`)
                  .color
              }`"
            >
              <span class="shrink-0 text-white text-xs font-medium">{{
                initials(`${emp.profile.firstName} ${emp.profile.lastName}`).str
              }}</span></span
            >

            <span class="grow cursor-pointer text-base font-medium">
              {{ `${emp.profile.firstName} ${emp.profile.lastName}` }}</span
            >
          </div>
        </div>
        <div
          v-else-if="
            data.title &&
            data.title.toLowerCase() === 'admin' &&
            adminData &&
            adminData.companyAdmin &&
            adminData.companyAdmin.firstName
          "
          class="flex flex-wrap min-w-full gap-2 justify-start items-center"
        >
          <div
            class="flex items-center justify-around gap-x-3 rounded-lg px-2 py-0.5 text-center bg-newLimeGreen border border-primary text-xs w-auto"
          >
            <span
              class="w-8 h-8 inline-flex flex-none items-center justify-center rounded-full"
              :style="`background-color:${
                initials(
                  `${adminData.companyAdmin.firstName} ${adminData.companyAdmin.lastName}`
                ).color
              }`"
            >
              <span class="shrink-0 text-white text-xs font-medium">{{
                initials(
                  `${adminData.companyAdmin.firstName} ${adminData.companyAdmin.lastName}`
                ).str
              }}</span></span
            >

            <span class="grow cursor-pointer text-sm font-medium">
              {{
                `${adminData.companyAdmin.firstName} ${adminData.companyAdmin.lastName}`
              }}</span
            >
          </div>
        </div>
        <div v-else>N/A</div>

        <div
          class="flex flex-col w-full md:flex-row items-start justify-between md:gap-6 gap-8"
        >
          <div class="flex flex-col w-full">
            <div
              class="p-2 bg-newDirtyBrown w-full text-center mb-4 text-dark-800 text-base"
            >
              What Role Can Access
            </div>
            <div class="overflow-y-auto max-h-80">
              <div
                v-for="item in data.privileges"
                :key="item._id"
                class="flex items-center w-full my-2 justify-between pr-5"
              >
                <span class="font-medium text-dark-800">{{
                  formatRolePrivilege(item.title)
                }}</span>
                <img src="@/assets/icons/newCheckMark.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full text-center">
            <div
              class="p-2 bg-secondary-crepe w-full mb-4 text-dark-800 text-base"
            >
              What Role Cannot Access
            </div>
            <div class="overflow-y-auto max-h-80">
              <div
                v-for="item in inAccessible"
                :key="item"
                v-show="!rolePrivs.includes('ALL')"
                class="flex w-full items-center my-2 justify-between pr-5"
              >
                <span
                  v-if="formatPrivilege(item)"
                  class="font-medium text-dark-800"
                  >{{ formatPrivilege(item) }}</span
                >
                <img
                  v-if="formatPrivilege(item)"
                  src="@/assets/icons/cancel-red.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- <keep-alive>
        <component :is="display" />
      </keep-alive> -->
        </div>
      </div>
    </div>

    <div
      v-else
      class="flex flex-col gap-4 w-full text-sm h-auto md:w-8/12 bg-white md:border-l pl-6 border-dark-900"
    >
      <div
        class="flex items-center justify-between border-b pb-4 border-dividerColor"
      >
        <h1 class="font-bold text-xl">Edit Role</h1>
        <span
          @click="isEdit = false"
          class="cursor-pointer text-secondary items-center"
        >
          Cancel
        </span>
      </div>

      <EditRole
        @completed="closeFN"
        :data="allPrivileges"
        :roleData="visibleData"
        :edit="true"
      />
    </div>

    <easiModal v-if="addRole" @close="addRole = false">
      <template v-slot:header
        ><h1 class="text-headerText font-medium text-3xl">
          Create New Role
        </h1></template
      >
      <div class="w-full px-5">
        <EditRole @completed="closeFN" :data="allPrivileges" />
      </div>
    </easiModal>

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import EditRole from "@/components/Settings/Edit/EditRole.vue";

//// View Components ////
import Company from "@/components/Settings/Business/Company.vue";
import Founder from "@/components/Settings/Business/Founder.vue";
import Directors from "@/components/Settings/Business/Directors.vue";
import Branch from "@/components/Settings/Business/Branch.vue";
import AccountInfo from "@/components/Settings/Business/AccountInfo.vue";

const store = useDataStore();
const { pageIndex } = storeToRefs(store);
const { query, mutate } = store;
const fromArchived = computed(() => store.getViewFromArchived);
const allRoles = computed(() => store.getRoles.data);
const allPrivileges = computed(() => store.getPrivileges.data);
const adminData = computed(() => store.getCompanyAdmin);

const addRole = ref(false);
const loading = ref(false);
const activeId = ref("");
const visibleData = ref([]);
activeId.value = allRoles.value[0]._id;
const rolePrivs = ref([]);
const defaultRoles = ref(["auditor", "admin", "uploader"]);
const inAccessible = ref([]);

function filterPrivileges() {
  const data = allRoles.value.filter((role) => role._id === activeId.value);
  visibleData.value = data;
  const privileges = allPrivileges.value.map((p) => p.title);
  const rolePrivilege = visibleData.value[0].privileges.map((p) => p.title);
  inAccessible.value = privileges.filter((p) => !rolePrivilege.includes(p));
  const privs = visibleData.value.map((i) => {
    return i.privileges;
  });
  rolePrivs.value = privs[0].map((p) => p.title);
}

onMounted(() => {
  filterPrivileges();
});

const isEdit = ref(false);
function formatRolePrivilege(text) {
  return text.replace(/_/g, " ");
}

function formatPrivilege(text) {
  if (text !== "ALL" && text) {
    return text.replace(/_/g, " ");
  }
  return null;
}

function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const initials = computed(() => {
  return (name) => {
    let color;
    // Get color

    const arr = name.split(" ");
    let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
    const initialOne = String(arr[0]).charAt(0);
    const initialTwo = String(arr[1]).charAt(0);
    const num =
      Number(alphabet.indexOf(initialOne.toLowerCase())) +
      Number(alphabet.indexOf(initialTwo.toLowerCase()));
    color = getColor(num);
    const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
      .charAt(0)
      .toUpperCase()}`;
    return { str, color };
  };
});

async function queryRole() {
  loading.value = true;

  try {
    await query({
      endpoint: "ListRoles",
      service: "SETTINGS",
      storeKey: "roles",
    });
    loading.value = false;
  } catch (e) {
    loading.value = false;
  }
}
async function queryPrivileges() {
  try {
    await query({
      endpoint: "ListPrivileges",
      service: "SETTINGS",
      storeKey: "privileges",
    });
  } catch (e) {}
}

const fetchCompanyAdminDetails = async () => {
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    console.log(e);
  }
};
fetchCompanyAdminDetails();
queryPrivileges();
queryRole();

async function closeFN() {
  isEdit.value = false;
  addRole.value = false;
  await queryRole();
}

const employeesWithRole = [
  {
    profile: {
      firstName: "Mike",
      lastName: "Strings",
    },
  },
];

const accessible = [
  "Add Employee",
  "Edit Employee",
  "Archive Employee",
  "Run Payroll",
  "Add Salary",
  "Edit Salary",
  "Add Bonus and Deduction",
  "Add Compliance",
  "Pay Compliance",
  "Edit Compliance",
  "Manage Account",
];

const switchView = (id) => {
  activeId.value = id;
  rolePrivs.value = [];
  visibleData.value = [];
  filterPrivileges();
};
</script>

<style></style>
